import React, { useContext } from 'react'
import { GrailGridContext } from '../App'

export const Board = () => {
    const { tiles, tileSelected, handleShow, startOver, guessesRemaining, showScore } = useContext(GrailGridContext);

    // IMAGES 
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690813632/grailgrid/pngegg_ugjn6q.png" alt="Tennis" width="100%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693078416/grailgrid/baseball_rof1v6.png" alt="Baseball" width="80%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1691676460/grailgrid/cavs_gifazq.png" alt="Cavs" width="80%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693341091/grailgrid/deion_fzduhq.png" alt="Deion" width="100%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690812672/grailgrid/nike_lgo_zclinl.png" alt="Nike" width="80%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1692881903/grailgrid/lakers_rtaqep.png" alt="Lakers" width="100%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693334622/grailgrid/jeter-removebg-preview_lku4dq.png" alt="Jeter" width="80%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1692366740/grailgrid/nbaec_optywx.png" alt="Eastern" width="100%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693342783/grailgrid/football_cilcnv.png" alt="Football" width="90%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1691159479/grailgrid/reebokgray_qzl6qb.png" alt="Reebok" width="80%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693337376/grailgrid/pippen_fhi6qy.png" alt="Pippen" width="100%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693315382/grailgrid/griffey_mnqkoe.png" alt="Griffey" width="90%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690813289/grailgrid/Daco_4096284_tffaaf.png" alt="Adidas" width="80%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1692969238/grailgrid/i3-removebg-preview_ooykuv.png" width="80%" alt="Iverson"/>
    // <img className="board-logos" src="https://1000logos.net/wp-content/uploads/2017/08/Kobe-Bryant-Symbol.jpg" alt="Kobe" width="80%"/>
    // <img className="board-logos" src="https://1000logos.net/wp-content/uploads/2017/07/Symbol-Kyrie-Irving.jpg" alt="Kyrie" width="90%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1692709300/grailgrid/western_uvwydp.png" alt="Western" width="90%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1691934826/grailgrid/IMG_1789_gsbf2d.jpg" width="80%" alt="LeBron"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690896098/grailgrid/jordan_d19ojn.png" alt="Jordan" width="80%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690813548/grailgrid/basketball_kcj4rr.png" alt="Basketball" width="95%"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1691326087/grailgrid/kd_ahhgrt.png" alt="Durant" width="80%"/>
    // <img src="https://res.cloudinary.com/loanmysole/image/upload/v1694203739/grailgrid/pngegg_2_o03bse.png" className="board-logos" width="80%" alt="Yankees"/>
    // <img src="https://res.cloudinary.com/loanmysole/image/upload/v1694474516/grailgrid/nfl_tqcnuy.png" className="board-logos" width="80%" alt="NFL"/>
    // <img src="https://res.cloudinary.com/loanmysole/image/upload/v1694474474/grailgrid/finalswin_e9z0de.png" className="board-logos" width="80%" alt="Finals"/>
    // <img src="https://res.cloudinary.com/loanmysole/image/upload/v1695820563/grailgrid/tinker_irzdmb.png" className="board-logos" width="75%" alt="Tinker"/>
    // <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1696960612/grailgrid/Avia-1979-s_pawpgr.png" alt="Avia" width="80%"/>
    // Barkley => CB34
    // Payton => GP20

    const attributes = new Map([
        ["Avia", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1696960612/grailgrid/Avia-1979-s_pawpgr.png" alt="Avia" width="80%"/>],
        ["Nike", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690812672/grailgrid/nike_lgo_zclinl.png" alt="Nike" width="80%"/>],
        ["Adidas", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690813289/grailgrid/Daco_4096284_tffaaf.png" alt="Adidas" width="80%"/>],
        ["Reebok", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1691159479/grailgrid/reebokgray_qzl6qb.png" alt="Reebok" width="80%"/>],
        ["basketball", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690813548/grailgrid/basketball_kcj4rr.png" alt="Basketball" width="95%"/>],
        ["tennis", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690813632/grailgrid/pngegg_ugjn6q.png" alt="Tennis" width="100%"/>],
        ["patentLeather", "Patent Leather"],
        ["high", "High Top"],
        ["kobe", <img className="board-logos" src="https://1000logos.net/wp-content/uploads/2017/08/Kobe-Bryant-Symbol.jpg" alt="Kobe" width="80%"/>],
        ["neon", "Neon"],
        ["iverson", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1692969238/grailgrid/i3-removebg-preview_ooykuv.png" width="80%" alt="Iverson"/>],
        ["durant", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1691326087/grailgrid/kd_ahhgrt.png" alt="Durant" width="80%"/>],
        ["eastern", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1692366740/grailgrid/nbaec_optywx.png" alt="Eastern" width="100%"/>],
        ["western", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1692709300/grailgrid/western_uvwydp.png" alt="Western" width="90%"/>],
        ["mj", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1690896098/grailgrid/jordan_d19ojn.png" alt="Jordan" width="80%"/>],
        ["low", "Low Top"],
        ["mid", "Mid Top"],
        ["tinker", <img src="https://res.cloudinary.com/loanmysole/image/upload/v1695820563/grailgrid/tinker_irzdmb.png" className="board-logos" width="75%" alt="Tinker"/>],
        ["finalswin", <img src="https://res.cloudinary.com/loanmysole/image/upload/v1694474474/grailgrid/finalswin_e9z0de.png" className="board-logos" width="80%" alt="Finals"/>],
        ["straps", "Straps"],
        ["agassi", "Agassi"],
        ["lakers", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1692881903/grailgrid/lakers_rtaqep.png" alt="Lakers" width="100%"/>],
        ["kyrie", <img className="board-logos" src="https://1000logos.net/wp-content/uploads/2017/07/Symbol-Kyrie-Irving.jpg" alt="Kyrie" width="90%"/>],
        ["cavs", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1691676460/grailgrid/cavs_gifazq.png" alt="Cavs" width="80%"/>],
        ["lebron", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1691934826/grailgrid/IMG_1789_gsbf2d.jpg" width="80%" alt="LeBron"/>],
        ["barkley", "CB34"],
        ["pippen", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693337376/grailgrid/pippen_fhi6qy.png" alt="Pippen" width="100%"/>],
        ["payton", "GP20"],
        ["baseball", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693078416/grailgrid/baseball_rof1v6.png" alt="Baseball" width="80%"/>],
        ["griffey", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693315382/grailgrid/griffey_mnqkoe.png" alt="Griffey" width="90%"/>],
        ["football", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693342783/grailgrid/football_cilcnv.png" alt="Football" width="90%"/>],
        ["jeter", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693334622/grailgrid/jeter-removebg-preview_lku4dq.png" alt="Jeter" width="80%"/>],
        ["deion", <img className="board-logos" src="https://res.cloudinary.com/loanmysole/image/upload/v1693341091/grailgrid/deion_fzduhq.png" alt="Deion" width="100%"/>],
        ["NFL", <img src="https://res.cloudinary.com/loanmysole/image/upload/v1694474516/grailgrid/nfl_tqcnuy.png" className="board-logos" width="80%" alt="NFL"/>],
        ["yankees", <img src="https://res.cloudinary.com/loanmysole/image/upload/v1694203739/grailgrid/pngegg_2_o03bse.png" className="board-logos" width="80%" alt="Yankees"/>],
    ]);

    return (
        <div className="grailgrid-board-container">
            <div className="grailgrid-board">
                {
                    tiles.map((tile, index) => {
                        return (
                            <span className="grailgrid-cell-containers" key={index} style={{alignSelf: "center"}}>
                                {
                                    tile.x1 ?
                                    <div className="grailgrid-cell titles">
                                        {attributes.get(tile.x1)}
                                    </div>
                                    :
                                    tile.x2 ?
                                    <div className="grailgrid-cell titles">
                                        {attributes.get(tile.x2)}
                                    </div>
                                    :
                                    tile.x3 ?
                                    <div className="grailgrid-cell titles">
                                        {attributes.get(tile.x3)}
                                    </div>
                                    :
                                    tile.y1 ?
                                    <div className="grailgrid-cell titles">
                                        {attributes.get(tile.y1)}
                                    </div>
                                    :
                                    tile.tile ?
                                    <div 
                                        onClick={
                                            () => tile.answer ? null 
                                            : 
                                            handleShow(tile.tile)} 
                                            className={
                                                tile.answer ? 
                                                "correct" 
                                                : 
                                                tileSelected === tile.tile ? 
                                                "grailgrid-cell game-tiles selected" 
                                                : 
                                                "grailgrid-cell game-tiles"
                                            }
                                            style={
                                                tile.tile === 1 ?
                                                {borderTopLeftRadius: "10px"}
                                                :
                                                tile.tile === 3 ?
                                                {borderTopRightRadius: "10px"}
                                                :
                                                tile.tile === 7 ?
                                                {borderBottomLeftRadius: "10px"}
                                                :
                                                tile.tile === 9 ?
                                                {borderBottomRightRadius: "10px"}
                                                :
                                                null
                                            }>
                                                <img alt={tile.name} src={tile.image} width="100%"/>
                                                <span className="rarity">
                                                    {
                                                        tile.rarity === "" ?
                                                        null
                                                        :
                                                        parseFloat(tile.rarity).toFixed(2)
                                                    }
                                                </span>
                                            </div>
                                    :
                                    tile.y2 ?
                                    <div className="grailgrid-cell titles">
                                        {attributes.get(tile.y2)}
                                    </div>
                                    :
                                    tile.y3 ?
                                    <div className="grailgrid-cell titles">
                                        {attributes.get(tile.y3)}
                                    </div>
                                    :
                                    null
                                }
                            </span>
                        )
                    })
                }
                <div></div>
                <div></div>
                {/* <div className="start-over-container">
                    <button className="start-over" onClick={startOver}>Start Over</button>
                </div> */}
                {
                    parseInt(guessesRemaining) === 0 ?
                    <button onClick={showScore} className="view-score">View Score</button>
                    :
                    null
                }
            </div>
            <div className="guesses-container">
                <p>GUESSES LEFT</p>
                <span className="guesses">{guessesRemaining}</span>
            </div>
        </div>
    )
}