import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import { GrailGridContext } from '../App'

export const ScoreModal = () => {
    const { totalCorrect, gridNumber, correctGuesses, showScoreModal, closeScore, score, rank, loadingRank, loadingScore, setShowLogin } = useContext(GrailGridContext);

    const prepTweet = () => {
        const link = encodeURI(window.location.href);
        let gridLayout = ""
        for(let i = 0; i < correctGuesses.length; i++) {
            if(Object.keys(correctGuesses[i]).length !== 0) {
                if(i % 3 === 2) {
                    gridLayout += `\uD83D\uDFE9 \n`;
                } else {
                    gridLayout += `\uD83D\uDFE9`;
                }
            } else {
                if(i % 3 === 2) {
                    gridLayout += `\u2B1C \n`;
                } else {
                    gridLayout += `\u2B1C`;
                }
            }
        }
        
        const msg = encodeURIComponent(
            `Grail Grid ${gridNumber}  ${totalCorrect}/9:\nScore: ${parseFloat(score).toFixed(2)}\n${gridLayout}\nPlay at:\n`
        );

        const twitter = document.querySelector('.twitter');
        twitter.href = `https://twitter.com/share?url=${link}&text=${msg}`;
    }

    return (
        <Modal show={showScoreModal} onHide={closeScore} className="score-modal">
            <Modal.Body>
                <div className="score-container">
                    <p className="score-title">Thanks for Playing!</p>
                    <p>Grail Grid #{gridNumber}</p>
                    <div className="score-board">
                        {
                            correctGuesses.map((answer, index) => {
                                return (
                                    <div className={Object.keys(answer).length !== 0 ? "score-tile score-tile-correct" : "score-tile"} key={index}></div>
                                )
                            })
                        }
                    </div>
                    <p>{totalCorrect}/9</p>
                    <div className="row">
                        <div className="col">
                            <p style={{fontSize: "25px", margin: "0"}}><b>Score</b></p>
                            {
                                loadingScore ?
                                <div style={{textAlign: "center"}}>
                                    <div className="spinner-border" role="status"></div>
                                </div>
                                :
                                <p style={{fontSize: "25px", color: "gray"}}>{parseFloat(score).toFixed(2)}</p>
                            }
                        </div>
                        <div className="col">
                            <p style={{fontSize: "25px", margin: "0"}}><b>Rank</b></p>
                            {
                                loadingRank ?
                                <div style={{textAlign: "center"}}>
                                    <div className="spinner-border" role="status"></div>
                                </div>
                                :
                                <p style={{fontSize: "25px", color: "gray"}}>{rank}</p>
                            }
                        </div>
                    </div>

                    <a href="javascript:undefined" className="twitter media-button mx-2" onClick={prepTweet} target="_blank"><img src="https://res.cloudinary.com/loanmysole/image/upload/v1691430618/logo-white_g7gwjw.png" width="20px"/> Share</a>

                    {/* localStorage.getItem("bank") === null */}
                    {/* <a href="javascript:undefined" className="media-button mx-2" ></a> */}
                    <button className="bank-score-button media-button mx-2" onClick={() => {setShowLogin(true); closeScore();}} disabled={localStorage.getItem("bank") !== null}>Bank Score</button>
                </div>
                <br/>
            </Modal.Body>
        </Modal>
    )
}
