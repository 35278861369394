import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import { GrailGridContext } from '../App'
import { Input } from './Input';
import { FaEyeSlash, FaEye } from 'react-icons/fa';

export const LoginModal = () => {
    const { showLogin, setShowLogin, userName, setUsername, password, setPassword, hidden, setHidden, login, setShowSignUp, loadLogin, errorMessage, showScore } = useContext(GrailGridContext);
    const [eye, setEye] = useState(<FaEyeSlash />);

    const toggleShow = () => {
        setHidden(!hidden);
        setEye(hidden ? <FaEye /> : <FaEyeSlash />)
    }

    return (
        <Modal show={showLogin} onHide={setShowLogin} className="rules-modal">
            <Modal.Body>
                <div className="rules-container">
                    {
                        loadLogin ?
                        <>
                            <div style={{textAlign: "center"}}>
                                <div className="spinner-border" role="status"></div>
                            </div>
                        </>
                        :
                        <>
                            <div style={{width: "100%", textAlign: "center"}}>
                                Bank Score With Your Loan My Sole Account
                            </div>
                            <br/>
                            {
                                errorMessage !== "" ?
                                <p className="text-center error">{errorMessage}</p>
                                :
                                null
                            }
                            <div className="rules-details">
                                <form onSubmit={login} className="lms-form text-center">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="userName"
                                        value={userName}
                                        changeHandler={e => setUsername(e.target.value)}
                                        fieldName="Username"/>
                                    <div className="password-requirements">
                                        <div className="input-group">    
                                            <Input
                                                type={hidden ? "password" : "text"}
                                                className="form-control mb-4"
                                                id="password"
                                                value={password}
                                                changeHandler={e => setPassword(e.target.value)}
                                                fieldName="Password"/>

                                            <div className="input-group-append">
                                                <span className="input-group-text icon-height toggle-show-icon" id="basic-addon1" role="button" onClick={toggleShow}>{eye}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="lms-button my-2" type="submit">CONFIRM</button>
                                    <button className="lms-button my-2" type="button" onClick={() => {setShowLogin(false); showScore(true);}}>VIEW SCORE</button>
                                </form>
                                <div className="login-bottom-row">
                                    {/* <p><small>Don't have an account? <span onClick={() => {setShowLogin(false); setShowSignUp(true);}}>Sign Up</span></small></p> */}
                                    <p><small>Don't have an account? <a href="https://www.enjoywears.com/" target='_blank' style={{color: "gray"}}>Sign Up</a></small></p>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}
