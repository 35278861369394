import React from 'react'

export const Input = (props) => {
    return (
        <div className="field-holder">
            <>
                <input 
                    type={`${props.type}`}
                    className={`${props.className}`}
                    id={`${props.id}`}
                    name={`${props.id}`}
                    aria-describedby={`${props.ariaDescribedby}`}
                    value={props.value}
                    onChange={props.changeHandler}
                    onFocus={props.showReqs !== undefined ? props.showReqs : null}
                    required/>
                <label htmlFor={`${props.id}`}>{props.fieldName}</label>
            </>
        </div>
    )
}
