import React, { useContext } from 'react'
import GrailGrid from '../assets/gglms.png';
import { FaSignInAlt } from 'react-icons/fa';
import { GrailGridContext } from '../App'

export const NavBar = () => {
    const { setShowLogin } = useContext(GrailGridContext);

    return (
        <nav className="nav-bar">
            <div className="nav-col logo-container">
                {/* <a href="https://www.enjoywears.com/"><img alt="Grail Grid Powered by Loan My Sole" src={GrailGrid}/></a> */}
                <a href="https://www.enjoywears.com/"><img alt="Grail Grid Powered by Wears" src="https://res.cloudinary.com/loanmysole/image/upload/v1713215389/wears-logo/Asset_1_white_3.24.24_aurepx.png"/></a>

                
            </div>
            {/* <div className="nav-col login-button-container">
                <FaSignInAlt onClick={() => setShowLogin(true)} className="login-button"/>
            </div> */}
        </nav>
    )
}