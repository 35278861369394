import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import { GrailGridContext } from '../App'

export const RulesModal = () => {
    const { showRulesModal, closeRules } = useContext(GrailGridContext);

    return (
        <Modal show={showRulesModal} onHide={closeRules} className="rules-modal">
            <Modal.Body>
                <div className="rules-container">
                    <div style={{width: "100%", textAlign: "center"}}>
                        <h1>HOW TO PLAY GRAIL GRID</h1>
                    </div>
                    <br/>
                    <div className="rules-details">
                        <p>Select a sneaker for each cell that matches the criteria for the cell's row and column.</p>
                        <p>You have nine guesses to fill out the grid.</p>
                        <p>Each guess, whether correct or incorrect, counts as a guess.</p>
                        <p>There is a new grid every day.</p>
                        <p>A shoe cannot be used twice.</p>
                        <p>The lower the score the better.</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
