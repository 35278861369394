import React, { useContext, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal';
import { GrailGridContext } from '../App'
import { GrailSelection } from './GrailSelection';

function debounce(fn, delay) {
    let timer;
    return function () {
        let context = this,
            args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
}

export const SearchModal = () => {
    const { showSelections, setShowSelections, setSearch, handleClose, show, search, setDebouncedSearch, submitHandler } = useContext(GrailGridContext);
    
    const changeHandler = async (event) => {
        setShowSelections(false);
        setSearch(event.target.value);
        searchGrails(event.target.value);
    }

    const searchGrails = useCallback(debounce((val)=>{
        setDebouncedSearch(val);
        if(val !== "") {
            setShowSelections(true);
        } else {
            setShowSelections(false);
        }
    }
    , 300), []);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <div>
                    <form className="form" onSubmit={submitHandler}>
                        
                        <input
                            type="text"
                            className="search-input"
                            value={search}
                            onChange={changeHandler}
                            required
                            placeholder="Search Grail..."/>
                        <button className="search-icon-button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="search-icon"><path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd"></path></svg></button>
                    </form>
                </div>
                {
                    showSelections ? <GrailSelection/> : null
                }
            </Modal.Body>
        </Modal>
    )
}
