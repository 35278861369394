import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import { GrailGridContext } from '../App'

export const LoadingModal = () => {
    const { showLoadingModal, closeLoading } = useContext(GrailGridContext);

    return (
        <Modal show={showLoadingModal} onHide={closeLoading} className="loading-modal">
            <div className="spinner-border" role="status"></div>
        </Modal>
    )
}
