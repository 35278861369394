import React, { useEffect, useRef, useState, createContext } from 'react'
import { NavBar } from './components/NavBar';
import { Board } from './components/Board';
import { SearchModal } from './components/SearchModal';
import { grailsJSON } from './utils/grails';
import { ScoreModal } from './components/ScoreModal';
import { Disclaimer } from './components/Disclaimer';
import { RulesModal } from './components/RulesModal';
import { LoadingModal } from './components/LoadingModal';
import './App.css';
import { LoginModal } from './components/LoginModal';
import { SignUpModal } from './components/SignUpModal';

export const GrailGridContext = createContext(null);

function App() {
    const effectRan = useRef(false);
    const firstRender = useRef(true);
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [tileSelected, setTileSelected] = useState("");
    const [show, setShow] = useState(false);
    const [showScoreModal, setShowScoreModal] = useState(false);
    const [showRulesModal, setShowRulesModal] = useState(true);
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [showSelections, setShowSelections] = useState(false);
    const [grails, setgrails] = useState({ ...grailsJSON });
    const [guessesRemaining, setGuessesRemaining] = useState(9);
    const [correctGuesses, setCorrectGuesses] = useState([{},{},{},{},{},{},{},{},{}]);
    const [loading, setLoading] = useState(true);
    const [loadingRank, setLoadingRank] = useState(false);
    const [loadingScore, setLoadingScore] = useState(false);
    const [totalCorrect, setTotalCorrect] = useState(0);
    const [score, setScore] = useState(900);
    const [rank, setRank] = useState(0);
    const [totalScores, setTotalScores] = useState([]);
    const [currentAnswers, setCurrentAnswers] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [insertId, setInsertId] = useState("");
    const [hidden, setHidden] = useState(true);
    const [loadLogin, setLoadLogin] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [gridNumber, setGridNumber] = useState("");
    const [grid, setGrid] = useState({});
    const [tilesSet, setTilesSet] = useState(false);
    const [tiles, setTiles] = useState([
        { header: true },
        { x1: "" },
        { x2: "" },
        { x3: "" },
        { y1: "" },
        { tile: 1, answer: false, name: "", image: "", rarity: "" },
        { tile: 2, answer: false, name: "", image: "", rarity: "" },
        { tile: 3, answer: false, name: "", image: "", rarity: "" },
        { y2: "" },

        { tile: 4, answer: false, name: "", image: "", rarity: "" },
        { tile: 5, answer: false, name: "", image: "", rarity: "" },
        { tile: 6, answer: false, name: "", image: "", rarity: "" },
        { y3: "" },
        { tile: 7, answer: false, name: "", image: "", rarity: "" },
        { tile: 8, answer: false, name: "", image: "", rarity: "" },
        { tile: 9, answer: false, name: "", image: "", rarity: "" },
    ]);

    useEffect(() => {
        if(effectRan.current === false) {
            (async () => {
                await getTodaysGrid();
            })();
            return () => {
                effectRan.current = true;
            }
        }
    }, []);

    async function getScores() {
        if(localStorage.getItem('zjwmfnjaopwmkd') === null) {
            localStorage.clear();
            window.location.reload();
        } else {
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                },
            }
            let totalScoresRes;
            await fetch(`/api/rank/get/scores/${gridNumber}`, requestOptions)
            .then(res => res.json())
            .then(res => {
                totalScoresRes = res.data;
                setTotalScores(res.data);
            });
            return totalScoresRes;
        }
    }
    
    async function getAnswers(gridNumber) {
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        }
        let currentAnswers;
        await fetch(`/api/rank/get/answers/${gridNumber}`, requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === 1) {
                currentAnswers = res.data;
                setCurrentAnswers(currentAnswers);
                setShowLoadingModal(false);
            }
        });
        return currentAnswers;
    }

    useEffect(() => {
        handleClose();
    }, [guessesRemaining === 0]);

    useEffect(() => {
        (async () => {
            if(totalScores.length > 0) {
                const rank = await getRank(totalScores, parseFloat(parseFloat(score).toFixed(2)));
                setRank(rank);
                setLoadingRank(false);
            }
        })();
        
    }, [totalScores]);

    useEffect(() => {
        // console.log("effectRan.current 1 ", effectRan.current)
        // if(effectRan.current === false) {
        //     console.log("effectRan.current 2 ", effectRan.current)
        //     return () => {
        //         effectRan.current = true;
        //     }
        // } else {
        //     console.log("effectRan.current 3 ", effectRan.current)
        //     console.log("firstRender.current 1 ", firstRender.current)
        //     if(firstRender.current) {
        //         console.log("firstRender.current 2 ", firstRender.current)
        //         firstRender.current = false;
        //         return;
        //     } else {
        //         (async () => {
        //             console.log("firstRender.current 3 ", firstRender.current)
        //             console.log("checkIfNewOrReset and prepGrid")
        //             console.log("gridNumber ", gridNumber)
        //             await checkIfNewOrReset(gridNumber);
        //             await prepGrid(gridNumber);
        //         })();
        //     }
        // }
        if(firstRender.current) {
            firstRender.current = false;
            return;
        } else {
            (async () => {
                await checkIfNewOrReset(gridNumber);
                await prepGrid(gridNumber);
            })();
        }
    }, [tilesSet]);

    const getTodaysGrid = async () => {
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        }
        await fetch(`/api/board/todaysgrid`, requestOptions)
        .then(res => res.json())
        .then(async res => {
            if(res.success === 1) {
                setGrid({
                    x: [res.data.x1, res.data.x2, res.data.x3],
                    y: [res.data.y1, res.data.y2, res.data.y3]
                });
                setGridNumber(res.data.gridNumber);

                setTiles([
                    { header: true },
                    { x1: res.data.x1 },
                    { x2: res.data.x2 },
                    { x3: res.data.x3 },
                    { y1: res.data.y1 },
                    { tile: 1, answer: false, name: "", image: "", rarity: "" },
                    { tile: 2, answer: false, name: "", image: "", rarity: "" },
                    { tile: 3, answer: false, name: "", image: "", rarity: "" },
                    { y2: res.data.y2 },

                    { tile: 4, answer: false, name: "", image: "", rarity: "" },
                    { tile: 5, answer: false, name: "", image: "", rarity: "" },
                    { tile: 6, answer: false, name: "", image: "", rarity: "" },
                    { y3: res.data.y3 },
                    { tile: 7, answer: false, name: "", image: "", rarity: "" },
                    { tile: 8, answer: false, name: "", image: "", rarity: "" },
                    { tile: 9, answer: false, name: "", image: "", rarity: "" },
                ]);
                setTilesSet(true);
            }
        });
    }

    const prepGrid = async (gridNumber) => {
        const currentAnswers = await getAnswers(gridNumber);
        const numOfGuesses = localStorage.getItem('guesses');
        if(numOfGuesses) {
            if(parseInt(numOfGuesses) === 0) {
                getScores();
            }
            setGuessesRemaining(localStorage.getItem('guesses'));
            const correct = JSON.parse(localStorage.getItem('correct'));
            if(correct) {
                setCorrectGuesses(correct);
                correct.forEach((value, index) => {
                    if(Object.keys(value).length !== 0) {
                        switch(index) {
                            case 0:
                                setAnswer(value, 5, index, currentAnswers);
                                break;
                            case 1:
                                setAnswer(value, 6, index, currentAnswers);
                                break;
                            case 2:
                                setAnswer(value, 7, index, currentAnswers);
                                break;
                            case 3:
                                setAnswer(value, 9, index, currentAnswers);
                                break;
                            case 4:
                                setAnswer(value, 10, index, currentAnswers);
                                break;
                            case 5:
                                setAnswer(value, 11, index, currentAnswers);
                                break;
                            case 6:
                                setAnswer(value, 13, index, currentAnswers);
                                break;
                            case 7:
                                setAnswer(value, 14, index, currentAnswers);
                                break;
                            case 8:
                                setAnswer(value, 15, index, currentAnswers);
                                break;
                            default: break;
                        }
                    }
                })
            } else {
                setLoading(false);
            }
            if(numOfGuesses === "0") {
                prepScoreBoard();
            }
        } else {
            setLoading(false);
        }
    }

    const checkIfNewOrReset = async (gridNumber) => {
        if(gridNumber !== parseInt(localStorage.getItem('gridnumber'))) {
            localStorage.clear();
            localStorage.setItem('gridnumber', gridNumber);
        }
    }

    const updateScore = async (score, id) => {
        setLoadingRank(true);
        const updateScoreVal = {
            score: score,
            gridNumber: gridNumber,
            id: id
        }
        const requestOptions = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateScoreVal)
        }
        await fetch("/api/rank/update/score", requestOptions)
        .then(res => res.json())
        .then(async res => {
            const totalScores = await getScores();
            const rank = await getRank(totalScores, parseFloat(parseFloat(score).toFixed(2)));
            setRank(rank);
            setLoadingRank(false);
        });
    }

    const getRank = async (arr, val) => {
        const id = parseInt(localStorage.getItem("zjwmfnjaopwmkd").split("/")[1]);
        setInsertId(id);
        let index = arr.map(e => e.id).indexOf(id);
        if(index !== -1) {
            if(val !== arr[index].score) {
                await updateScore(val, id);
            } else {
                return parseInt(((index + 1)/(arr.length + 1)) * 10000);
            }
        } else {
            localStorage.clear();
            window.location.reload();
        }
    }

    const setAnswer = async (selection, tile, guessSlot, currentAnswers) => {
        const tempTiles = tiles;
        let tempTile = { ...tempTiles[tile] };
        tempTile.answer = true;
        tempTile.name = selection.name === undefined ? selection : selection.name;
        tempTile.image = selection.image === undefined ? grails[selection].image: selection.image;
        switch(tile) {
            case 5:
                tempTile = calculateRarity(tempTile, selection, currentAnswers, 1);
                break;
            case 6:
                tempTile = calculateRarity(tempTile, selection, currentAnswers, 2);
                break;
            case 7:
                tempTile = calculateRarity(tempTile, selection, currentAnswers, 3);
                break;
            case 9:
                tempTile = calculateRarity(tempTile, selection, currentAnswers, 4);
                break;
            case 10:
                tempTile = calculateRarity(tempTile, selection, currentAnswers, 5);
                break;
            case 11:
                tempTile = calculateRarity(tempTile, selection, currentAnswers, 6);
                break;
            case 13:
                tempTile = calculateRarity(tempTile, selection, currentAnswers, 7);
                break;
            case 14:
                tempTile = calculateRarity(tempTile, selection, currentAnswers, 8);
                break;
            case 15:
                tempTile = calculateRarity(tempTile, selection, currentAnswers, 9);
                break;
        }
        tempTiles[tile] = tempTile;
        setTiles([ ...tempTiles ]);
        let tempCorrectGuesses;
        if(localStorage.getItem('correct')) {
            tempCorrectGuesses = JSON.parse(localStorage.getItem('correct'));
        } else {
            tempCorrectGuesses = [...correctGuesses];
        }

        tempCorrectGuesses[guessSlot] = grails[selection] === undefined ? selection : grails[selection];
        if(selection.name === undefined) {
            tempCorrectGuesses[guessSlot].name = selection;
            tempCorrectGuesses[guessSlot].active = true;
            
            setgrails({
                ...grails,
                [selection]: {
                    ...[selection],
                    active: true
                }
            });
        } else {
            setgrails({
                ...grails,
                [selection.name]: {
                    ...selection,
                    active: true
                }
            });
        }
        setCorrectGuesses([ ...tempCorrectGuesses ]);
        localStorage.setItem('correct', JSON.stringify(tempCorrectGuesses));
        handleClose();
        setLoading(false);
        await addAnswer(selection);
        if(parseInt(guessesRemaining) === 1) {
            await prepScoreBoard();
        } else if(parseInt(localStorage.getItem('guesses')) === 0) {
            setLoadingScore(false);
        }
    }

    const calculateRarity = (tempTile, selection, currentAnswers, tile) => {
        if(currentAnswers.length === 0) {
            tempTile = {
                ...tempTile,
                rarity: 100
            };
        } else {
            const currentAnswersForTile = [];
            let countOfAnswerForTile = 0;
            for(let i = 0; i < currentAnswers.length; i++) {
                const next = i !== currentAnswers.length - 1 ? i + 1 : i;
                if(i === next) {
                    if(currentAnswers[i].tile === tile) {
                        currentAnswersForTile.push(currentAnswers[i]);
                    }
                } else {
                    if(currentAnswers[i].tile === tile) {
                        currentAnswersForTile.push(currentAnswers[i]);
                        if(currentAnswers[next].tile !== tile) {
                            break;
                        }
                    }
                }
            }

            if(selection.name === undefined) {
                if(!currentAnswersForTile.some(answer => answer.answer === selection)) {
                    currentAnswersForTile.push({
                        board: gridNumber,
                        answer: selection,
                        tile: tile,
                        count: 0
                    });
                }
            }
            currentAnswersForTile.sort((a,b) => (a.answer > b.answer) ? 1 : ((b.answer > a.answer) ? -1 : 0))

            countOfAnswerForTile = currentAnswersForTile.reduce(function(a, b){
                return a + b.count;
            }, 0);
            
            for(let i = 0; i < currentAnswersForTile.length; i++) {
                if(currentAnswersForTile[i].answer === tempTile.name && currentAnswersForTile[i].tile === tile) {
                    if(selection.name === undefined) {
                        countOfAnswerForTile++;
                        currentAnswersForTile[i].count = currentAnswersForTile[i].count + 1;
                    }
                    tempTile.rarity = (currentAnswersForTile[i].count/countOfAnswerForTile) * 100;
                    break                        
                }
            }
        }
        return tempTile;
    }

    const setIncorrect = async () => {
        if(parseInt(localStorage.getItem('guesses')) === 0) {
            handleShow();
        } else {
            handleClose();
        }
        if(parseInt(guessesRemaining) === 1) {
            await prepScoreBoard();
        }
    }

    const determineLogic = (yIndex, xIndex, selection, tile, guessSlot) => {
        let xAnswer = false;
        let yAnswer = false;
        if(grails[selection][grid.x[xIndex]]) {
            xAnswer = true;
        }
        if(grails[selection][grid.y[yIndex]]) {
            yAnswer = true;
        }
        if(xAnswer && yAnswer){
            setAnswer(selection, tile, guessSlot, currentAnswers);
        } else {
            setIncorrect(selection, tile);
        }
    }

    const submitAnswer = (selection) => {
        if(grails[selection].active) {
            alert("Answer already used.");
        } else {
            switch(tileSelected) {
                case 1:
                    determineLogic(0, 0, selection, 5, 0);
                    break;
                case 2:
                    determineLogic(0, 1, selection, 6, 1);
                    break;
                case 3:
                    determineLogic(0, 2, selection, 7, 2);
                    break;
                case 4:
                    determineLogic(1, 0, selection, 9, 3);
                    break;
                case 5:
                    determineLogic(1, 1, selection, 10, 4);
                    break;
                case 6:
                    determineLogic(1, 2, selection, 11, 5);
                    break;
                case 7:
                    determineLogic(2, 0, selection, 13, 6);
                    break;
                case 8:
                    determineLogic(2, 1, selection, 14, 7);
                    break;
                case 9:
                    determineLogic(2, 2, selection, 15, 8);
                    break;
                default: break;
            }
            localStorage.setItem('guesses', guessesRemaining - 1);
            setGuessesRemaining(guessesRemaining - 1);
        }
    }

    // const addGameEntries = async (score) => {
    //     setLoadingRank(true);
    //     setLoadingScore(true);
    //     showScore();
    //     const addGameEntriesVal = {
    //         tile1: tiles[5].name,
    //         tile2: tiles[6].name,
    //         tile3: tiles[7].name,
    //         tile4: tiles[9].name,
    //         tile5: tiles[10].name,
    //         tile6: tiles[11].name,
    //         tile7: tiles[13].name,
    //         tile8: tiles[14].name,
    //         tile9: tiles[15].name,
    //     }
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(addGameEntriesVal)
    //     }
    //     await fetch("/api/rank/add/game_entries", requestOptions)
    //     .then(res => res.json())
    //     .then(async res => {
    //         setLoadingScore(false);
    //         await addScore(score);
    //     });
    // }

    const addScore = async (score) => {
        const addScoreVal = {
            score: score,
            gridNumber: gridNumber
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(addScoreVal)
        }
        await fetch("/api/rank/add/score", requestOptions)
        .then(res => res.json())
        .then(async res => {
            localStorage.setItem("zjwmfnjaopwmkd", `${generateString()}/${res.data.insertId}/${generateString()}`);
            setInsertId(res.data.insertId);
            const totalScores = await getScores();
            const rank = await getRank(totalScores, parseFloat(parseFloat(score).toFixed(2)));
            setRank(rank);
            setLoadingRank(false);
        });
    }

    const generateString = () => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < charactersLength) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const addAnswer = async (selection) => {
        if(tileSelected) {
            const addAnswerVal = {
                board: gridNumber,
                answer: selection,
                tile: tileSelected,
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(addAnswerVal)
            }
            await fetch("/api/rank/add/answer", requestOptions)
            .then(res => res.json())
            .then(async res => {
                // console.log(res)
            });
        }
    }

    const handleClose = () => {
        setShowSelections(false);
        setTileSelected("");
        setSearch("");
        setDebouncedSearch("");
        setShow(false);
    }

    const handleShow = (tile) => {
        if(guessesRemaining > 0) {
            setTileSelected(tile);
            setShow(true);
        } else {
            showScore();
        }
    }

    const prepScoreBoard = async () => {
        setLoadingRank(true);
        setLoadingScore(true);
        showScore();
        if(parseInt(guessesRemaining) === 1) {
            const score = await calculateScore();
            await addScore(score);
        } else if(parseInt(localStorage.getItem('guesses')) === 0) {
            await calculateScore();
        }
        setLoadingRank(false);
        setLoadingScore(false);
    }

    const calculateScore = async () => {
        let rarityTotal = 0;
        let emptyTiles = 0;
        let totalCorrect = 0;
        tiles.forEach(tile => {
            if(tile.rarity && tile.rarity !== "") {
                totalCorrect++;
                rarityTotal = rarityTotal + tile.rarity
            } else if(tile.rarity === "") {
                emptyTiles = emptyTiles + 100;
            }
        });
        rarityTotal = emptyTiles + rarityTotal;
        setTotalCorrect(totalCorrect);
        setScore(rarityTotal);
        return rarityTotal;
    }

    const showScore = async () => {
        setShowScoreModal(true);
    }

    const closeScore = () => {
        setShowScoreModal(false);
    }

    const closeRules = () => {
        setShowRulesModal(false);
    }

    const closeLoading = () => {
        setShowLoadingModal(false);
    }

    const startOver = () => {
        // setTileSelected("");
        // setTiles([
        //     { header: true },
        //     { x1: "Non Low Top" },
        //     { x2: "Kobe" },
        //     { x3: "Durant" },
        //     { y1: "low" },
        //     { tile: 1, answer: false },
        //     { tile: 2, answer: false },
        //     { tile: 3, answer: false },
        //     { y2: "Non Low Top" },
        //     { tile: 4, answer: false },
        //     { tile: 5, answer: false },
        //     { tile: 6, answer: false },
        //     { y3: "Nike" },
        //     { tile: 7, answer: false },
        //     { tile: 8, answer: false },
        //     { tile: 9, answer: false },
        // ]);
        // setgrails({ ...grailsJSON })
    }

    const selectAnswer = (grail) => {
        setSearch(grail);
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        let selection = "";
        const possibleAnswers = Object.keys(grails).filter((grail) => {
            return debouncedSearch.toLowerCase() === "" ?
            grail.toLowerCase()
            :
            grail.toLowerCase().includes(debouncedSearch.toLowerCase())
        });
        for(let i = 0; i < possibleAnswers.length; i++) {
            if(possibleAnswers[i].localeCompare(search, undefined, {sensitivity: 'base'}) === 0) {
                selection = possibleAnswers[i];
                break;
            }
        }
        if(selection !== "") {
            await submitAnswer(selection);
        } else {
            if(parseInt(guessesRemaining) === 1) {
                await prepScoreBoard();
            }
            handleClose();
            localStorage.setItem('guesses', guessesRemaining - 1);
            setGuessesRemaining(guessesRemaining - 1);
        }
    }

    const login = async (event) => {
        event.preventDefault();
        setLoadLogin(true);

        const userInfo = {
            userName: userName,
            password: password,
            gridNumber: gridNumber,
            score: score,
            insertId: insertId
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userInfo)
        }
        await fetch("/api/rank/bankscore", requestOptions)
        .then(res => res.json())
        .then(async res => {
            if(res.success === 1) {
                setErrorMessage("");
                localStorage.setItem("bank", "false");
                setShowLogin(false);
                showScore();
                alert("Score successfully banked!");
            } else {
                localStorage.setItem("bank", "false");
                setErrorMessage(res.data);
            }
            setLoadLogin(false);
        });
    }

    return (
        <GrailGridContext.Provider value={{
            search, setSearch,
            debouncedSearch, setDebouncedSearch,
            tileSelected, setTileSelected,
            show, setShow,
            showSelections, setShowSelections,
            showScoreModal, closeScore,
            showRulesModal, closeRules,
            showLoadingModal, closeLoading,
            totalCorrect, setTotalCorrect,
            showLogin, setShowLogin,
            showSignUp, setShowSignUp,
            userName, setUsername,
            password, setPassword,
            hidden, setHidden,
            errorMessage, setErrorMessage,
            correctGuesses,
            gridNumber,
            submitAnswer,
            handleClose,
            handleShow,
            startOver,
            grails,
            tiles,
            guessesRemaining,
            score,
            submitHandler,
            selectAnswer,
            rank,
            loadingRank,
            loadingScore,
            showScore,
            login,
            loadLogin
        }}>
           <div className="grailgrid">
            <NavBar/>
            <LoginModal/>
            <SignUpModal/>
                {
                    loading ?
                    <></>
                    :
                    <Board/>
                }
                {
                    ((parseInt(localStorage.getItem('guesses')) > 0 || localStorage.getItem('guesses') === undefined || localStorage.getItem('guesses') === null)) && window.location.href !== "http://localhost:3000/" ?
                    <RulesModal/>
                    :
                    showLoadingModal ?
                    <div style={{height: "80%"}}>
                        <LoadingModal/>
                    </div>
                    :
                    null
                }
                <SearchModal/>
                <ScoreModal/>
                <Disclaimer/>
           </div>
        </GrailGridContext.Provider>
    );
}

export default App;
