import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import { GrailGridContext } from '../App'
import { Input } from './Input';
import { FaEyeSlash, FaEye } from 'react-icons/fa';

export const SignUpModal = () => {
    const { 
        setShowLogin,
        userName,
        setUsername,
        password,
        setPassword,
        hidden,
        setHidden,
        login,
        showSignUp, 
        setShowSignUp
    } = useContext(GrailGridContext);
    const [eye, setEye] = useState(<FaEyeSlash />);

    const toggleShow = () => {
        setHidden(!hidden);
        setEye(hidden ? <FaEye /> : <FaEyeSlash />)
    }

    return (
        <Modal show={showSignUp} onHide={setShowSignUp} className="rules-modal">
            <Modal.Body>
                <div className="rules-container">
                    <div style={{width: "100%", textAlign: "center"}}>
                        Login with Loan My Sole Account
                    </div>
                    <br/>
                    <div className="rules-details">
                        {/* <form onSubmit={login} className="lms-form text-center">
                            <Input
                                type="text"
                                className="form-control"
                                id="userName"
                                value={userName}
                                changeHandler={e => setUsername(e.target.value)}
                                fieldName="Username"/>
                            <div className="password-requirements">
                                <div className="input-group">    
                                    <Input
                                        type={hidden ? "password" : "text"}
                                        className="form-control mb-4"
                                        id="password"
                                        value={password}
                                        changeHandler={e => setPassword(e.target.value)}
                                        fieldName="Password"/>

                                    <div className="input-group-append">
                                        <span className="input-group-text icon-height toggle-show-icon" id="basic-addon1" role="button" onClick={toggleShow}>{eye}</span>
                                    </div>
                                </div>
                            </div>
                            <button className="lms-button my-2" type="submit">LOGIN</button>
                        </form> */}
                        <div className="login-bottom-row">
                            <p><small>Already have an account? <span onClick={() => {setShowLogin(true); setShowSignUp(false);}}>Log In</span></small></p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
