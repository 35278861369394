export const grailsJSON = 
{
    "Air Jordan 1 Black/Red (Banned)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/054/488/691/original/51181_01.jpg.jpeg?action=crop&width=950",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 56,
        "mj": true,
    },

    "Air Jordan 1 White/Black/Red (Chicago)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/083/401/929/original/14741_01.jpg.jpeg?action=crop&width=600",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "neon":  false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 85,
        "mj": true,
    },

    "Air Jordan 1 White/Red/Black (Black Toe)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/084/528/original/64796_01.jpg.jpeg?action=crop&width=600",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 42,
        "mj": true,
    },

    "Air Jordan 1 Black/Royal Blue (Royals)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/136/481/original/121225_01.jpg.jpeg?action=crop&width=600",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 61,
        "mj": true,
    },

    "Air Jordan 1 Grey/Black (Shadows)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/454/035/original/218099_01.jpg.jpeg?action=crop&width=600",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 38,
        "mj": true,
    },

    "Air Jordan 1 White/Powder Blue": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/077/928/421/original/31395_01.jpg.jpeg?action=crop&width=600",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 22,
        "mj": true,
    },

    "Air Jordan 2 White/Black": {
        "Jordan": true,
        "image": "https://sneakernews.com/wp-content/uploads/2022/03/air-jordan-2-og-white-varsity-red-black-october-2022-2.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 37,
        "mj": true,
    },

    "Air Jordan 2 Low White/Black": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/080/196/964/original/46483_01.jpg.jpeg?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 40,
        "mj": true,
    },

    "Air Jordan 2 White/Red": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/016/692/original/395718_102.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "eastern": true,
        "western": false,
        "rarity": 57,
        "mj": true,
    },

    "Air Jordan 2 Low White/Red": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/027/695/original/309837_101.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 24,
        "mj": true,
    },

    "Air Jordan 3 White/Cement": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/082/913/710/original/1101598_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 51,
        "mj": true,
    },

    "Air Jordan 3 Black/Cement": {
        "Jordan": true,
        "image": "https://images.stockx.com/images/Air-Jordan-3-Retro-Black-Cement-1994.jpg?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1613360980",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 48,
        "mj": true,
    },

    "Air Jordan 3 White/Fire Red": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/076/061/384/original/DM0966_160.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 42,
        "mj": true,
    },

    "Air Jordan 3 White/Cement Grey/True Blue": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/087/138/496/original/1152280_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 41,
        "mj": true,
    },

    "Air Jordan 4 White/Black": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/071/929/457/original/935565_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 39,
        "mj": true,
    },

    "Air Jordan 4 Black/Cement Grey": {
        "Jordan": true,
        "image": "https://images.stockx.com/images/Air-Jordan-4-OG-Black-Cement-1989.jpg?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1607053057",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "durant": false,
        "iverson": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 40.4,
        "mj": true,
    },

    "Air Jordan 4 Off-White/Military Blue": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/077/963/664/original/13649_01.jpg.jpeg?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "durant": false,
        "iverson": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 50.3,
        "mj": true,
    },

    "Air Jordan 4 White/Fire Red/Black": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/046/247/197/original/612313_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 56,
        "mj": true,
    },

    "Air Jordan 5 White/Black/Fire Red": {
        "Jordan": true,
        "image": "https://www.nicekicks.com/files/2019/11/air-jordan-5-white-black-fire-red-1200x639.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 34.7,
        "mj": true,
    },

    "Air Jordan 5 Black/Metallic Silver": {
        "Jordan": true,
        "image": "https://img.stadiumgoods.com/jordan-air-jordan-5-retro-og-black-metallic_13031416_45867650_1000.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 38.7,
        "mj": true,
    }, 

    "Air Jordan 5 White/Grape/Emerald": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/083/760/610/original/13553_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 28.8 ,
        "mj": true,
    
    }, 

    "Air Jordan 5 White/Black/Fire Red/": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/081/096/205/original/529559_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 39,
        "mj": true,
    },   

    "Air Jordan 6 White/Carmine": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/050/395/655/original/632801_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 18,
        "mj": true,
    }, 

    "Air Jordan 14 Black/Varsity Red (Last Shots)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/714/768/original/350311_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 17,
        "mj": true,
    },

    "Air Jordan 13 Black/True Red/White (Playoffs) ": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/325/380/original/136002_061.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 27.4,
        "mj": true,
    },

    "Air Jordan 13 White/True Red/Black (He Got Game)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/006/096/original/218110_01.jpg.jpeg?action=crop&width=700",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 29.4,
        "mj": true,
    },

    "Air Jordan 13 Black/Varsity Red (Breds)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/044/842/078/original/132786_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 34.2,
        "mj": true,
    },

    "Air Jordan 13 White/Black/True Red/Pearl Grey": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/032/656/920/original/414571_101_S.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 20.8,
        "mj": true,
    },

    "Air Jordan 13 Navy/Carolina Blue/Flint Grey/White (Flints)": {
        "Jordan": true,
        "image": "https://hypebeast.com/image/2020/05/765tefa.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 14.4,
        "mj": true,
    },

    "Air Jordan 13 Low Navy/Metallic Silver/Black/Carolina Blue": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/461/339/original/124218_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "durant": false,
        "iverson": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 30 ,
        "mj": true,
    },

    "Air Jordan 13 Low Black/Chutney": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/924/746/original/125441_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 8.1,
        "mj": true,
    },

    "Air Jordan 12 White/Black/Taxi": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/296/028/original/15146_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 41,
        "mj": true,
    },

    "Air Jordan 12 Black/Varsity Red/White/Metallic Silver (Playoffs)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/066/248/034/original/858712_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 49.4,
        "mj": true,
    },

    "Air Jordan 12 White/Varsity Red/Black": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/688/404/original/151186_110.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 29,
        "mj": true,
    },

    "Air Jordan 12 Black/Varsity Red (Flu Games)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/080/647/original/46482_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "finalswin": true,
        "tinker": true,
        "rarity": 57,
        "mj": true,
    },

    "Air Jordan 11 White/Black/Dark Concord (Concords #45)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/047/069/787/original/351614_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 47,
        "mj": true,
    },

    "Air Jordan 11 Black/True Red/White (Breds)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/047/069/784/original/478948_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 55 ,
        "mj": true,
    },

    "Air Jordan 11 White/Black/Dark Concord (Concords #23)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/047/069/787/original/351614_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 29,
        "mj": true,
    },

    "Air Jordan 11 White/Columbia Blue/Black": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/136/313/original/13607_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 53.1,
        "mj": true,
    
    },

    "Air Jordan 11 Black/Dark Concord/White (Space Jams)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/047/069/788/original/52015_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 40.1,
        "mj": true,
    },

    "Air Jordan 11 Cool Grey": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/043/664/854/original/15010_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "finalswin": true,
        "tinker": true,
        "rarity": 30.3,
        "mj": true,
    },

    "Air Jordan 10 White/Black/Light Steel Grey": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/022/226/original/130209_101.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 21,
        "mj": true,
    },

    "Air Jordan 10 Black/Dark Shadow/True Red": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/051/628/858/original/352424_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 18.6,
        "mj": true,
    },

    "Air Jordan 10 White/Black/Dark Powder Blue": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/022/227/original/130209_102.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 11.8,
        "mj": true,
    },

    "Air Jordan 10 White/Black/True Red/Chicago": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/461/088/original/13630_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 40,
        "mj": true,
    },

    "Air Jordan 10 TBD (Knicks)": {
        "Jordan": true,
        "image": "https://images.stockx.com/images/Air-Jordan-10-OG-New-York.jpg?fit=fill&bg=FFFFFF&w=1200&h=857&fm=jpg&auto=compress&dpr=2&trim=color&updated_at=1618364353&q=75",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 8,
        "mj": true,
    },

    "Air Jordan 9 White/Black/True Red (Playoffs)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/077/928/082/original/61622_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 46.3,
        "mj": true,
    },

    "Air Jordan 9 White/Black/Dark Powder Blue": {
        "Jordan": true,
        "image": "https://image-cdn.hypb.st/https%3A%2F%2Fhypebeast.com%2Fimage%2F2023%2F03%2Fair-jordan-9-powder-blue-2024-release-info-00.jpg?w=960&cbr=1&q=90&fit=max",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 37.3,
        "mj": true,
    },

    "Air Jordan 9 Black/Light Olive/True Red": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/083/345/358/original/13664_01.jpg.jpeg?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 27.5,
        "mj": true,
    },

    "Air Jordan 9 Black/Dark Charcoal/True Red": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/066/735/493/original/16818_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 29.5,
        "mj": true,
    },

    "Air Jordan 9 Cool Grey": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/084/178/288/original/13595_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 45,
        "mj": true,
    },

    "Air Jordan 8 White/Black/True Red": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/022/219/original/130169_100.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "straps": true,
        "tinker": true,
        "finalswin": true,
        "rarity": 41.67,
        "mj": true,
    },

    "Air Jordan 8 Black/True Red (Playoffs)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/052/619/158/original/16578_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "straps": true,
        "tinker": true,
        "finalswin": true,
        "rarity": 23.92,
        "mj": true,
    },

    "Air Jordan 8 Black/Bright Concord/Aqua Tone": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/080/557/original/30210_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "straps": true,
        "tinker": true,
        "finalswin": true,
        "rarity": 18.3,
        "mj": true,
    },

    "Air Jordan 8": {
        "Jordan": true,
        "image": "https://image.goat.com/attachments/product_template_additional_pictures/images/081/145/046/medium/229596_12.jpg.jpeg?1669133103",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "high": true,
        "kobe": true,
        "active": false,
        "neon":false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "straps": true,
        "finalswin": true,
        "rarity": 6.8,
        "mj": true,
    },

    "Air Jordan 7 Black/Dark Charcoal/True Red (Raptors)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/080/386/original/13589_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "rarity": 22.3,
        "mj": true,
    },

    "Air Jordan 7 Black/Light Graphite/Bordeaux": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/077/929/757/original/14492_01.jpg.jpeg?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 31.0,
        "mj": true,
    },

    "Air Jordan 7 White/Black/Cardinal Red (Cardinals)": {
        "Jordan": true,
        "image": "https://2app.kicksonfire.com/kofapp/upload/events_master_images/ipad_air-jordan-7-cardinal-2022.jpeg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 26.3,
        "mj": true,
    },

    "Air Jordan 7 White/Light Silver (Hares)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/056/825/336/original/15054_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 17.7,
        "mj": true,
    },

    "Air Jordan 7 White/Midnight Navy/True Red (Olympics)": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/723/734/original/15060_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 27.2,
        "mj": true,
    },
    "Air Jordan 7 White/French Blue": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/715/251/original/182_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": false,
        "tinker": true,
        "finalswin": true,
        "rarity": 13.9,
        "mj": true,
    },

    "Adidas Stan Smith Neon": {
        "Adidas": true,
        "image": "https://images.squarespace-cdn.com/content/v1/580cf7ce6b8f5b3576a3da9f/1630873413901-DNT60EUZZB8YL21ZZDIF/248804.jpeg",
        "basketball": false,
        "tennis": true,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "active": false,
        "neon": true,
        "iverson": false,
        "durant": false,
        "eastern": false,
        "western": false,
        "rarity": 46    
    },

     "Adidas Stan Smith": {
        "Adidas": true,
        "image": "https://images.stockx.com/images/Adidas-Stan-Smith-Vintage-OG-Green.png?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1615418100",
        "basketball": false,
        "tennis": true,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "active": false,
        "neon": false, 
        "iverson": false,
        "durant": false,
        "eastern": false,
        "western": false,
        "rarity": 49
     },    

    "Nike Air Trainer 1": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/016/749/827/original/306530_001.png.png?action=crop&width=600",
        "basketball": false,
        "tennis": true,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": true,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": false,
        "western": false,
        "straps": true,
        "tinker": true,
        "rarity": 53
    },

    "Nike Air Trainer 1 112 Pack": {
        "Nike": true,
        "image": "https://img.stadiumgoods.com/nike-air-trainer-1-premium-112-pack_14406867_45871954_1000.jpg",
        "basketball": false,
        "tennis": true,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "neon": true,
        "durant": false,
        "iverson": false,
        "eastern": false,
        "western": false,
        "tinker": true,
        "straps": true,
        "rarity": 28
    },    

    "Nike Air Tech Challenge 2 Turbo Green": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/016/601/original/318408_130.png.png?action=crop&width=600",
        "basketball": false,
        "tennis": true,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": true,
        "neon": true,
        "durant": false,
        "eastern": false,
        "western": false,
        "iverson": false,
        "tinker": true,
        "rarity": 25
    },

    "Reebok Pump": {
        "Reebok": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/074/926/151/original/HR0035.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": true,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": true,
        "neon": true,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": true,
        "rarity": 17.5

    },

    "Nike Air Challenge LWP": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/011/121/087/original/140326_031.png.png?action=crop&width=600",
        "basketball": true,
        "tennis": true,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": true,
        "mj": true,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": true,
        "tinker": true,
        "rarity": 14

    },

    "Adidas EQT Elevation": {
        "Adidas": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/020/653/original/Q33087.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "neon": false,
        "durant": false,
        "iverson": false,
        "eastern": false,
        "western": true,
        "rarity": 19
    },

    "Adidas KB8": {
        "Adidas": true,
        "image": "https://images.solecollector.com/complex/image/upload/f_auto,fl_lossy,q_auto,w_1200/oeelfuyuou9avjlncq7p.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "mid": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "neon": false,
        "durant": false,
        "iverson": false,
        "eastern": false,
        "western": true,
        "rarity": 63
    },

    "Adidas KB8 Neon Tribal": {
        "Adidas": true,
        "image": "https://image.goat.com/750/attachments/product_template_pictures/images/029/729/803/original/S84984.png.png",
        "basketball": false,
        "tennis": true,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "neon": true,
        "iverson": false,
        "durant": false,
        "eastern": false,
        "western": true,
        "rarity": 12
    
    },    

    "Adidas KB8 2": {
        "Adidas": true,
        "image": "https://images.stockx.com/images/Adidas-Crazy-2-Nike-Kobe-Home.png?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1606935444",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "neon": false,
        "durant": false,
        "iverson": false,
        "eastern": false,
        "western": true,
        "rarity": 56
    },

    "Adidas KB8 3": {
        "Adidas": true,
        "image": "https://www.whatproswear.com/wp-content/uploads/2020/01/Vince-Carter-adidas-KB8-III-adidas-equt-responsive-shoes-toronto-raptors-1024x1024.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "neon": false,
        "durant": false,
        "iverson": false,
        "eastern": false,
        "western": true,
        "rarity": 69
    },

    "Adidas Kobe 1": {
        "Adidas": true,
        "image": "https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/a9a4f02291d64f0b8258ae31000d4c7c_9366/Crazy_1_Shoes_Yellow_GY3808_01_standard.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,    
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": false,
        "western": true,
        "finalswin": true,
        "rarity": 54

    },

    "Adidas Kobe 2": {
        "Adidas": true,
        "image": "https://kixstats.com/img/kicks/adidasKobe2-15.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,    
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": false,
        "western": true,
        "finalswin": true,
        "rarity": 7
    },

    "Reebok Question": {
        "Reebok": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/071/434/048/original/897953_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "mid": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": true,
        "neon": true,
        "durant": false,
        "eastern": true,
        "western": true,
        "rarity": 15.9
    },

    "Reebok Question Neon Cherry": {
        "Reebok": true,
        "image": "https://justforsports.com/cdn/shop/files/Reebok-Men-s-Hot-Ones-Question-Mid-Shoes-Neon-Cherry-Orange-Black-Yellow-Just-For-Sports-805_700x700.png?v=1683599806",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": true,
        "neon": true,
        "durant": false,
        "eastern": true,
        "western": true,
        "rarity": 2
    
    },

    "Nike Air Zoom Huarache 2k4": {
        "Nike": true,
        "image": "https://img.stadiumgoods.com/nike-air-zoom-huarache-2k4_14472974_42965029_1000.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "high": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "eastern": true,
        "western": true,
        "straps": true,
        "lakers": true,
        "rarity": 58
    },

    "Nike Air Zoom Huarache 2k4 Volt": {
        "image": "https://kicksigma.com/wp-content/uploads/2023/03/09F5D2B5-47FA-42E3-AF52-E5D156D7FE92.jpeg",
        "Nike": true,
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "high": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": true,
        "durant": false,
        "neon": true,
        "eastern": true,
        "western": true,
        "straps": true,
        "lakers": true,
        "rarity": 37
    },

    "Nike Kobe Zoom 1": {
        "Nike": true,
        "image": "https://d3pnpe87i1fkwu.cloudfront.net/IMG/nike-zoom-kobe-1-protro-aq2728-004_585x585.webp",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": false,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 43
    },

    "Nike Kobe Zoom 2": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/595/618/original/79711_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": false,
        "eastern": false,
        "western": true,
        "straps": true,
        "lakers": true,
        "rarity": 19
    },   

    "Nike Kobe Zoom 3": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/080/459/416/original/24082_01.jpg.jpeg?action=crop&width=600",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": false,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 38
    },

    "Nike Kobe Zoom 4": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/080/337/826/original/24034_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": false,
        "eastern": false,
        "western": true,
        "lakers":true,
        "finalswin": true,
        "rarity": 24
    },

    "Nike Kobe Zoom 5 Proto Chaos": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/644/421/original/558317_01.jpg.jpeg?action=crop&width=600",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": true,
        "eastern": false,
        "western": true,
        "lakers": true,
        "finalswin": true,
        "rarity": 15
    },

    "Nike Kobe Zoom 5": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/478/877/original/386429_102.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": false,
        "eastern": false,
        "western": true,
        "lakers": true,
        "finalswin": true,
        "rarity": 63

    },   

    "Nike Kobe Zoom 6 Proto Grinch": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/050/505/603/original/631511_01.jpg.jpeg?action=crop&width=600",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": true,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 66
    },

    "Nike Kobe Zoom 6": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/033/015/original/429913_001.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": false,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 41
    },
    
    "Nike Kobe Zoom 7": {
        "Nike": true,
        "image": "https://assets.solesense.com/en/images/products/500/nike-zoom-kobe-7-system-lakers-488371-101_1.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": false,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 55
    
    },

    "Nike Kobe Zoom 7 Cheetah": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/015/820/original/488371_800.png.png?action=crop&width=600",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": true,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 54
    },

    "Nike Kobe Zoom 8": {
        "Nike": true,
        "image": "https://www.kicksonfire.com/wp-content/uploads/2016/03/Nike-Kobe-8-Black-Mamba-1.png",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": false,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 30

    },    

    "Nike Kobe Zoom 8 Blitz Blue": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/036/538/original/555035_401.png.png?action=crop&width=950",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": true,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 11
    },

    "Nike Kobe Zoom 9": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/015/636/079/original/677992_XXX.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": true,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 61
    },

    "Nike Kobe Zoom 10": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/056/406/679/original/9483_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": true,
        "eastern": false,
        "western": true,
        "lakers": true,
        "rarity": 23.2
    },  

    "Nike Kobe Zoom 11": {
        "Nike": true,
        "image": "https://www.kickscrew.com/cdn/shop/products/main-square_00c95f3e-ff29-4e0a-b0b6-a189adc76307_504x.jpg?v=1686299447",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "durant": false,
        "neon": false,
        "eastern": false,
        "western": true,
        "lakers": true,
        "tinker": true,
        "rarity": 20.5
    },

    "Nike Air Maestro": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/001/236/199/original/310269_011.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": true,
        "rarity": 33.1
    },

    "Nike Air Force 93": {
        "Nike": true,
        "image": "https://www.sneakers.fr/wp-content/uploads/2018/02/nike-air-force-mid-1993-3.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": true,
        "straps": true,
        "rarity": 37.2
    },

    "Nike Air Strong": {
        "Nike": true,
        "image": "https://images.squarespace-cdn.com/content/v1/549f12bae4b05ce481f174ec/1521478121304-BU01OCQNG0J5ZIDBF03U/nike+air+strong+mid+black+white+.jpg?format=1500w",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": true,
        "rarity": 36.2
    },

    "Nike Air Up High": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/022/193/229/original/130206_012.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": true,
        "rarity": 30.1
    },

    "Nike Air Way Up": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/007/282/935/original/579945_002.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "eastern": true,
        "western": true,
        "rarity": 10.7
    },

    "Reebok The Answer": {
        "Reebok": true,
        "image": "https://www.kicksonfire.com/wp-content/uploads/2022/06/reebok-answer-1-og-white-red-2022-4.jpeg?x58464",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 37
    },

    "Reebok Answer 2": {
        "Reebok": true,
        "image": "https://sothebys-md.brightspotcdn.com/dims4/default/6be28d6/2147483647/strip/true/crop/5000x5000+0+0/resize/385x385!/quality/90/?url=http%3A%2F%2Fsothebys-brightspot.s3.amazonaws.com%2Fmedia-desk%2F02%2F4e%2F04c3ab0645bca51400a5a9654fd6%2Fmarketplace-ftwr-4671.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 29
    },

    "Reebok Answer 3": {
        "Reebok": true,
        "image": "https://images.solecollector.com/complex/image/upload/c_fill,f_auto,fl_lossy,q_auto,w_1100/dtho97jaxeifrnfzsbro.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 56
    },

    "Reebok Answer 4": {
        "Reebok": true,
        "image": "https://www.kicksonfire.com/wp-content/uploads/2023/02/Screen-Shot-2023-02-01-at-1-08-17-PM.png",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": true,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 18
    },

    "Reebok Answer 5": {
        "Reebok": true,
        "image": "https://img.stadiumgoods.com/reebok-answer-5_15946260_43066913_1000.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 54
    },

    "Reebok Answer 5 Low": {
        "Reebok": true,
        "image": "https://images.stockx.com/images/Reebok-Answer-5-Low-White-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1626899625",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 58.8
    },

    "Reebok Answer 6": {
        "Reebok": true,
        "image": "https://i.ebayimg.com/images/g/8uwAAOSwJwRj97qq/s-l1200.webp",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 60.3
    },
    
    "Reebok Answer 7": {
        "Reebok": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/031/481/354/original/4_101490.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 37.3
    },

    "Reebok Answer 8": {
        "Reebok": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/025/771/104/original/4_117777.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 49.4
    },

    "Reebok Answer 9": {
        "Reebok": true,
        "image": "https://i.pinimg.com/736x/be/25/4b/be254b246072f3643abdd7fa7ce61af2.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 41.2
    },

    "Reebok Answer 10": {
        "Reebok": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/017/762/235/original/4_160985.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 22.9
    },

    "Reebok Answer 11": {
        "Reebok": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/084/147/031/original/518885_01.jpg.jpeg?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 19.2
    },

    "Reebok Answer 12": {
        "Reebok": true,
        "image": "https://i.ebayimg.com/images/g/hUEAAOSwGi9hLdMP/s-l1200.webp",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 12.3
    },

    "Reebok Answer 13": {
        "Reebok": true,
        "image": "https://img.buzzfeed.com/buzzfeed-static/complex/images/eevyptp9mmvokhes0jbw-1_ukqjzy/None.jpg?downsize=900:*&output-format=auto&output-quality=auto",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 18.7
    },

    "Reebok Answer 14": {
        "Reebok": true,
        "image": "https://images.solecollector.com/complex/image/upload/kd0znc4alswxhiwl9fre.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": true,
        "durant": false,
        "eastern": true,
        "western": false,
        "rarity": 3.7
    },

    
    "Nike Zoom KD1": {
        "Nike": true,
        "image": "https://images.stockx.com/images/Nike-Nike-KD-1-OKC-Home.png?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1626899082",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "eastern": false,
        "western": true,
        "high": true,   
        "rarity": 20.3
    },

    "Nike Zoom KD2": {
        "Nike": true,
        "image": "https://i1.wp.com/static.epicbuzzer.com/uploads/2019/06/nike-kd-2-all-star-2010-png.jpeg?fit=1000%2C714&ssl=1",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "high": true,   
        "rarity": 30.1
    },

    "Nike Zoom KD3": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/084/406/original/448695_001.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "eastern": false,
        "western": true,
        "low": true,
        "rarity": 31.2
    },

    "Nike Zoom KD4": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/049/367/353/original/13333_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "low": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "rarity": 34
    },

    "Nike Zoom KD5": {
        "Nike": true,
        "image": "https://img.buzzfeed.com/buzzfeed-static/complex/image/upload/iacjv1uwcrkydc5hzh1b.jpg.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "eastern": false,
        "western": true,
        "high": true,   
        "rarity": 31.3
    },

    "Nike Zoom KD6": {
        "Nike": true,
        "image": "https://images.stockx.com/images/Nike-KD-6-Bamboo.jpg?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1623804995",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "low": true,
        "eastern": false,
        "western": true,
        "rarity": 32
    },

    "Nike Zoom KD7": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/051/754/783/original/14564_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "low": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "rarity": 32
    },

    "Nike Zoom KD8": {
        "Nike": true,
        "image": "https://images.stockx.com/images/Nike-KD-8-OKC-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1626900619",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "low": true,
        "eastern": false,
        "western": true,
        "rarity": 30.2
    },

    "Nike Zoom KD9": {
        "Nike": true,
        "image": "https://www.thehoopsgeek.com/IMG/nike-kd-9-elite-878637-010_1170x1170.png",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "eastern": false,
        "western": true,
        "finalswin": true,
        "mid": true,    
        "rarity": 32.1
    },

    "Nike Zoom KD10": {
        "Nike": true,
        "image": "https://basketzone.net/zdjecia/2017/07/14/107/17/NIKE_ZOOM_KD_10_COOL_GREY_IGLOO.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "eastern": false,
        "western": true,
        "finalswin": true,
        "mid": true,
        "rarity": 30.2
    },

    "Nike Zoom KD11": {
        "Nike": true,
        "image": "https://www.thehoopsgeek.com/IMG/nike-kd-11-ao2604-004_1170x1170.png",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "eastern": false,
        "western": true,
        "mid": true,
        "rarity": 31
    },

    "Nike Zoom KD12": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/080/808/075/original/534618_00.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": true,
        "iverson": false,
        "durant": true,
        "eastern": true,
        "western": false,
        "mid": true,
        "rarity": 33.2
    },

    "Nike Zoom KD13": {
        "Nike": true,
        "image": "https://dk.basketzone.net/zdjecia/2020/06/03/506/25/NIKE_ZOOM_KD_13_BLACK_GREY-mini.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "eastern": true,
        "western": false,
        "mid": true,    
        "rarity": 28
    },

    "Nike Zoom KD14": {
        "Nike": true,
        "image": "https://dks.scene7.com/is/image/GolfGalaxy/21NIKAKD14PPRT66XMNK_Black_Red_White?qlt=70&wid=600&fmt=pjpeg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "mid": true,
        "rarity": 31.2
    },

    "Nike Zoom KD15": {
        "Nike": true,
        "image": "https://2app.kicksonfire.com/kofapp/upload/events_master_images/ipad_nike-kd-15-white-black.png",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe": false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": true,
        "low": true,
        "eastern": true,
        "western": false,
        "rarity": 33.2
    },

    "Nike Kyrie 1": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/022/074/original/705277_270.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": true,
        "iverson": false,
        "durant": false,
        "kyrie": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "mid": true,
        "rarity": 36.7
    },
    
    "Nike Kyrie 2": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/001/185/128/original/826673_008.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "finalswin": true,
        "mid": true,
        "rarity": 45.6
    },
    
    "Nike Kyrie 3": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/082/591/902/original/155545_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "high": true,
        "rarity": 40
    },
    
    "Nike Kyrie 4": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/018/873/original/441807_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "high": true,
        "rarity": 43.5
    },
    
    "Nike Kyrie 5": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/018/119/original/471892_01.jpg.jpeg?action=crop&width=700",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": true,
        "eastern": true,
        "western": false,
        "mid": true,
        "rarity": 38.6
    },
    
    "Nike Kyrie 6": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/499/455/original/561274_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "mid": true,
        "rarity": 36.5
    },
    
    "Nike Kyrie 7": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/055/523/649/original/707765_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": true,
        "eastern": true,
        "western": false,
        "mid": true,
        "rarity": 43.8
    },
    
    "Nike Hyperdunk 2014": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/018/297/478/original/653640_078.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": true,
        "eastern": true,
        "western": false,
        "high": true,
        "rarity": 50
    },
    
    "Nike Kyrie Infinity": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/064/522/393/original/851857_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather":  false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": true,
        "eastern": true,
        "western": false,
        "mid": true,        
        "rarity": 37.7
    },

    "Nike Air Zoom Generation LeBron 1": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/055/689/097/original/191959_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "low": false,
        "cavs": true,
        "eastern": true,
        "western": false,
        "mid": true,
        "rarity": 50
    },
    
    "Nike Zoom LeBron 2": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/296/153/original/21190_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "low": false,
        "cavs": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "high": true,
        "rarity": 45
    },
    
    "Nike Zoom LeBron 3": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/084/081/719/original/488221_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "rarity": 26.6
    },
    
    "Nike Zoom LeBron 4": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/028/797/original/314647_011.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 34.7
    },
    
    "Nike Zoom LeBron 5": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/029/436/original/317253_141.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 38.9
    },
    
    "Nike Zoom LeBron 6": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/032/164/original/346526_101.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "rarity": 70
    },
    
    "Nike Zoom LeBron 7": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/032/556/original/375793_101.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "rarity": 41.8
    },
    
    "Nike Air Max Zoom LeBron 8": {
        "Nike": true,
        "image": "https://images.stockx.com/images/Nike-Lebron-8-Home.jpg?fit=fill&bg=FFFFFF&w=1200&h=857&fm=jpg&auto=compress&dpr=2&trim=color&updated_at=1620341161&q=75",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "eastern": true,
        "western": false,
        "rarity": 58.8
    },
    
    "Nike Air Max Zoom LeBron 9": {
        "Nike": true,
        "image": "https://sneakernews.com/wp-content/uploads/2013/01/lebron-9-archive.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "eastern": true,
        "western": false,
        "finalswin": true,
        "rarity": 38.4
    },
    
    "Nike Air Max Zoom LeBron X": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/015/912/original/579834_100.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "eastern": true,
        "western": false,
        "finalswin": true,
        "rarity": 50.7
    },
    
    "Nike Air Max Zoom LeBron 11": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/066/846/943/original/13157_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "rarity": 58
    },
    
    "Nike Air Max Zoom LeBron 12": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/051/754/757/original/12964_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "rarity": 33.7
    },
    
    "Nike Air Max Zoom LeBron 13": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/067/384/369/original/808710_008.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "rarity": 32
    },
    
    "Nike Air Max Zoom LeBron 14": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/002/319/645/original/852406_001.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 58.2
    },
    
    "Nike Air Max Zoom LeBron 15": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/009/820/original/437571_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": true,
        "western": false,
        "rarity": 85.8
    },
    
    "Nike Air Max Zoom LeBron 16": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/010/374/original/422155_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": false,
        "western": true,
        "rarity": 51.6
    },
    
    "Nike Air Max Zoom LeBron 17": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/079/482/281/original/549367_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "cavs": true,
        "eastern": false,
        "western": true,
        "rarity": 33.9
    },
    
    "Nike Air Max Zoom LeBron 18": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/045/030/284/original/686577_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "eastern": false,
        "western": true,
        "finalswin": true,
        "rarity": 24.8
    },
    
    "Nike LeBron 19": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/073/663/869/original/903422_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "high": true,
        "eastern": false,
        "western": true,
        "rarity": 36.8
    },
    
    "Nike LeBron Witness 6": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/065/107/120/original/853933_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "mid": true,
        "eastern": false,
        "western": true,
        "rarity": 16.1
    },
    
    "Nike LeBron Witness 7": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/088/506/922/original/DM1123_005.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "kobe":  false,
        "active": false,
        "agassi": false,
        "neon": false,
        "iverson": false,
        "durant": false,
        "kyrie": false,
        "lebron": true,
        "mid": true,
        "eastern": false,
        "western": true,
        "rarity": 21.7
    },   

    "Nike Air Force II": {
        "Nike": true,
        "image": "https://images.stockx.com/images/Nike-Air-Force-2-High-76ers.png?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1627415064",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": true,
        "western": false,
        "rarity": 63
    },

        "Nike Air Alpha Force I": {
        "Nike": true,
        "image": "https://www.onenessboutique.com/cdn/shop/files/ONENESS_0002_NIKE-AAF88-WHITE_UNIVERSITY-RED-DZ4627-100_ff25afb9-e95d-49a6-976c-117d624e0cf2_1500x.jpg?v=1689351135",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 87.8
    },

        "Nike Air Alpha Force II": {
        "Nike": true,
        "image": "https://www.kicksonfire.com/wp-content/uploads/2015/07/NIKE-AIR-ALPHA-FORCE-II-1.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "rarity": 85.7
    },

        "Nike Air Bound": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/047/428/603/original/307391_012.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": true,
        "western": false,
        "rarity": 20.7
    },

    "Nike Air Force 180": {
        "Nike": true,
        "image": "https://image.goat.com/attachments/product_template_additional_pictures/images/068/884/401/medium/13321_03.jpg.jpeg?1646755432",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "rarity": 86
    },

    "Nike Air Force Max": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/037/130/original/624021_001.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "rarity": 34
    },

    "Nike Air Force Max CB": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/014/748/795/original/AJ7922_001.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "rarity": 78.3
    },

    "Nike Air Max 2 CB": {
        "Nike": true,
        "image": "https://2app.kicksonfire.com/kofapp/upload/events_master_images/ipad_nike-air-max-cb-94-suns.jpeg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "rarity": 90
    },

    "Nike Air CB 34": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/003/648/067/original/316940_001.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "rarity": 62
    },

    "Nike Air CB 34 II": {
        "Nike": true,
        "image": "https://images.stockx.com/images/Nike-Air-Max-CB-34-Suns-Home-2011.png?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1626899464",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "rarity": 72.5
    },

    "Nike Air More Uptempo": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/078/081/274/original/141274_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "rarity": 21.4
    },

    "Nike Air CB4": {
        "Nike": true,
        "image": "https://images.pristineauction.com/34/345640/main_1-Charles-Barkley-Signed-Game-Used-Nike-Custom-CB4-Shoe-JSA-ALOA-PristineAuction.com.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "rarity": 100
    },

    "Nike Air Super CB": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/045/300/911/original/930108_111.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "rarity": 86
    },

    "Nike Air CB4 II": {
        "Nike": true,
        "image": "https://i.pinimg.com/1200x/1b/d0/18/1bd01822d390777ea57262cf8807013f.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": true,
        "eastern": false,
        "western": true,
        "rarity": 92
    },

    "Nike Air Pippen 1": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/057/725/243/original/524261_01.jpg.jpeg?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": true,
        "western": false,
        "finalswin": true,
        "rarity": 57.2
    },
    
    "Nike Air Pippen 2": {
        "Nike": true,
        "image": "https://images.stockx.com/images/Nike-Air-Pippen-2-Bulls-Home-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1627414925",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": true,
        "western": false,
        "finalswin": true,
        "rarity": 46.1
    },
    
    "Nike Air Pippen 3": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/022/169/original/830127_102.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": false,
        "western": true,
        "rarity": 50.7
    },
    
    "Nike Air Pippen 4": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/044/899/401/original/830170_001.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": false,
        "western": true,
        "rarity": 20.8
    },
    
    "Nike Air Pippen 5": {
        "Nike": true,
        "image": "https://sothebys-md.brightspotcdn.com/f3/fe/0921bb444c56a07968ada370d9a4/marketplace-ftwr-4683.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "rarity": 65.8
    },
    
    "Nike Air Pippen 6": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/054/791/409/original/123169_01.jpg.jpeg?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": false,
        "western": true,
        "rarity": 45.7
    },
    
    "Avia 885": {
        "Avia": true,
        "image": "https://avia.com/cdn/shop/files/AVIA855_AA50179M_BH_1_1200x.jpg?v=1683303950",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": true,
        "western": false,
        "rarity": 88.5
    },
    
    "Nike Air Flight Lite low": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/033/787/521/original/318644_002.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": true,
        "western": false,
        "finalswin": true,
        "rarity": 48.4
    },
    
    "Nike Air Flight Lite 2": {
        "Nike": true,
        "image": "https://i.ebayimg.com/images/g/bdwAAOSwHJ9j9UKf/s-l1200.webp",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": true,
        "western": false,
        "finalswin": true,
        "rarity": 24.2
    },
        
    "Nike Air Flight Huarache": {
        "Nike": true,
        "image": "https://img.buzzfeed.com/buzzfeed-static/images/ZmxfbG9zc3kscV9hdXRvL2NfY3JvcCxoXzEyMzcsd18xODk0LHhfNTgseV80NTE=/yveu96d2va12kv6vlmpl/nike-air-flight-huarache-bold-berry-fd0183-101-medial.jpeg.jpeg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": true,
        "western": false,
        "straps": true,
        "tinker": true,
        "finalswin": true,
        "rarity": 99.2
    },
        
    "Nike Air Dynamic Flight": {
        "Nike": true,
        "image": "https://i.pinimg.com/736x/75/9d/01/759d01ae3ff0e818d42c626408fe8b12.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": true,
        "western": false,
        "rarity": 33.9
    },
        
    "Nike Air Max Uptempo": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/032/806/original/399207_100.png.png?action=crop&width=500",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": true,
        "eastern": true,
        "western": false,
        "rarity": 33.9
    },

    "Nike Air Zoom Flight 98 The Glove": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/042/996/377/original/13405_01.jpg.jpeg?action=crop&width=850",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": true,
        "eastern": false,
        "western": true,
        "rarity": 59.7
    },
        
    "Nike Zoom Hawk Flight": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/023/926/921/original/830012_131.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": true,
        "eastern": false,
        "western": true,
        "rarity": 31
    },
        
    "Nike Zoom GP": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/084/358/369/original/481373_01.jpg.jpeg?action=crop&width=850",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "rarity": 36.7
    },
        
    "Nike Zoom GP II": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/011/783/659/original/830171_001.png.png?action=crop&width=850",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": true,
        "eastern": false,
        "western": true,
        "rarity": 83.1
    },
        
    "Nike Zoom GP III": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/005/693/870/original/830222_001.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": true,
        "eastern": false,
        "western": true,
        "straps": true,
        "rarity": 82.4
    },
        
    "Nike Zoom GP IV": {
        "Nike": true,
        "image": "https://miszapas.net/media/2001-02-Air-Zoom-GP-IV-Black.jpg",
        "basketball": true,
        "tennis": false,
        "patentLeather": true,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": true,
        "eastern": false,
        "western": true,
        "rarity": 20.1
    },
        
    "Nike Zoom Ultraflight": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/022/138/original/305515_141.png.png?action=crop&width=750",
        "basketball": true,
        "tennis": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": true,
        "eastern": false,
        "western": true,
        "rarity": 30.5
    },

    "Nike Air Griffey Max 1": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/077/311/039/original/1022196_01.jpg.jpeg?action=crop&width=850",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": true,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": true,
        "eastern": false,
        "western": false,
        "straps": true,
        "rarity": 17.4
    },
    
    "Nike Air Griffey Max 2": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_additional_pictures/images/055/571/903/original/24807_01.jpg.jpeg?action=crop&width=850",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": true,
        "eastern": false,
        "western": false,
        "rarity": 60.7
    },
    
    "Nike Air Max 360 Diamond Griffey": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/000/385/248/original/580398_001.png?action=crop&width=750",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": true,
        "eastern": false,
        "western": false,
        "rarity": 39.8
    },
    
    "Nike Air Max Griffey Fury": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/012/910/683/original/511309_003.png.png?action=crop&width=850",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": true,
        "eastern": false,
        "western": false,
        "rarity": 80.7
    },
    
    "Nike Air Max Jr.": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/034/725/343/original/442478_010.png.png?action=crop&width=750",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": true,
        "eastern": false,
        "western": false,
        "rarity": 47.8
    },

    "Nike Total Griffey Max 99": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/032/107/697/original/487676_143.png.png?action=crop&width=750",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "low": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": true,
        "eastern": false,
        "western": false,
        "rarity": 75
    },

    "Nike Air Diamond Turf": {
        "Nike": true,
        "image": "https://images.solecollector.com/complex/image/upload/pvapmpc084qjwe9bgvvy.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": false,
        "football": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": true,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": false,
        "deion": true,
        "eastern": false,
        "western": false,
        "straps": true,
        "NFL": true,
        "rarity": 42.4
    },
                    
    "Nike Air Diamond Turf II": {
        "Nike": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/006/418/609/original/488294_600.png?action=crop&width=750",
        "basketball": false,
        "tennis": false,
        "baseball": false,
        "football": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": true,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": false,
        "deion": true,
        "eastern": false,
        "western": false,
        "straps": true,
        "NFL": true,
        "rarity": 49.5
    },
                    
    "Nike Air Diamond Turf Max '96": {
        "Nike": true,
        "image": "https://images.stockx.com/images/Nike-Air-Diamond-Turf-Max-96-Black-Varsity-Maize.jpg?fit=fill&bg=FFFFFF&w=700&h=500&fm=webp&auto=compress&q=90&dpr=2&trim=color&updated_at=1626899400",
        "basketball": false,
        "tennis": false,
        "baseball": false,
        "football": true,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": true,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": false,
        "deion": true,
        "eastern": false,
        "western": false,
        "straps": true,
        "NFL": true,
        "rarity": 96.9
    },

    "Nike Air Diamond Turf IV": {
        "Nike": true,
        "image": "https://images.solecollector.com/complex/image/upload/agnzedijfd3gkenng6wp.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": false,
        "football": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": false,
        "deion": true,
        "eastern": false,
        "western": false,
        "straps": true,
        "NFL": true,
        "rarity": 76.2
    },
                        
    "Nike Air Diamond Turf V": {
        "Nike": true,
        "image": "https://images.solecollector.com/complex/image/upload/uqkwcdayno4bqtd05jbd.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": false,
        "football": true,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "mj": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": false,
        "deion": true,
        "eastern": false,
        "western": false,
        "straps": true,
        "NFL": true,
        "rarity": 85.5
    },

    "Jumpman Jeter Turn 2": {
        "Jordan": true,
        "image": "https://cconnect.s3.amazonaws.com/wp-content/uploads/2014/09/Jordan-Jumpman-Turn-2-Derek-Jeter-260x160.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 14.7,
        "mj": true,
    },
        
    "Jumpman Jeter DJ": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/067/924/113/original/306163_042.png.png?action=crop&width=750",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "straps": true,
        "yankees": true,
        "rarity": 29.2,
        "mj": true,
    },
        
    "Jumpman Jeter Jet": {
        "Jordan": true,
        "image": "https://www.cardboardconnection.com/wp-content/uploads/2014/09/Jordan-Jumpman-Jet-Derek-Jeter.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 51.8,
        "mj": true,
    },
        
    "Jumpman Jeter Six4three": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/027/806/849/original/311718_102.png.png?action=crop&width=750",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 17.1,
        "mj": true,
    },
        
    "Jumpman Jeter Official": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/027/518/845/original/313312_103.png?action=crop&width=850",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 33.2,
        "mj": true,
    },
        
    "Jumpman Jeter Vital": {
        "Jordan": true,
        "image": "https://i.ebayimg.com/images/g/YZMAAOSwNu1k0PzR/s-l2400.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 92,
        "mj": true,
    },
        
    "Jumpman Jeter Clutch": {
        "Jordan": true,
        "image": "https://image.goat.com/transform/v1/attachments/product_template_pictures/images/028/174/993/original/318594_101.png.png?action=crop&width=750",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 59.4,
        "mj": true,
    },
        
    "Jordan Jeter Captain": {
        "Jordan": true,
        "image": "https://www.kicksonfire.com/wp-content/uploads/2009/05/jordan-jeter-captain-2.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 30.6,
        "mj": true,
    },
        
    "Jordan Jeter Throwback": {
        "Jordan": true,
        "image": "https://sneakernews.com/wp-content/uploads/2010/03/air-jordan-jeter-throwback-black-3.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": true,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 97.4,
        "mj": true,
    },
        
    "Jordan Jeter Cut": {
        "Jordan": true,
        "image": "https://d1l5jyrrh5eluf.cloudfront.net/wp-content/uploads/2011/07/Jordan-Jeter-Cut-stealthwhite-no-tag.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": false,
        "high": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 53.7,
        "mj": true,
    },
        
    "Jordan Jeter Legend": {
        "Jordan": true,
        "image": "https://www.cardboardconnection.com/wp-content/uploads/2014/09/Jordan-Legend-Derek-Jeter.jpg",
        "basketball": false,
        "tennis": false,
        "baseball": true,
        "patentLeather": true,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": true,
        "eastern": false,
        "western": false,
        "yankees": true,
        "rarity": 21.9,
        "mj": true,
    },

    "Adidas EQT Key Trainer": {
        "Adidas": true,
        "image": "https://andscape.com/wp-content/uploads/2022/10/EQT-KEY-TRAINER.jpg?w=700",
        "basketball": false,
        "tennis": false,
        "baseball": false,
        "patentLeather": false,
        "mid": true,
        "kobe": false,
        "active": false,
        "agassi": false,
        "iverson": false,
        "neon": false,
        "durant": false,
        "barkley": false,
        "pippen": false,
        "payton": false,
        "griffey": false,
        "jeter": false,
        "eastern": false,
        "western": false,
        "yankees": false,
        "NFL": true,
        "mj": false,
    },



}