import React, { useContext } from 'react'
import { GrailGridContext } from '../App';

export const GrailSelection = () => {
    const { submitAnswer, debouncedSearch, grails, search } = useContext(GrailGridContext);

    return (
        <div className="grail-selection-container">
            {
                Object.keys(grails).filter((grail) => {
                    return debouncedSearch.toLowerCase() === "" ?
                    grail.toLowerCase()
                    :
                    grail.toLowerCase().includes(debouncedSearch.toLowerCase())
                }).map((grail,  index) => {
                    return (
                        <>
                            {
                                (search.length/grail.length) * 100 >= 20 ?
                                <div className="grail-selection" onClick={() => submitAnswer(grail)}>
                                    {grail}
                                </div>
                                :
                                null
                            }
                        </>
                    )
                })
            }
        </div>
    )
}
